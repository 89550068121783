import React from "react";
import { i18n } from "../util/i18n";
import { Link } from "@mui/material";

/**
 * A React component that resets alls filters to their default values
 */

const ResetFiltersButton = ({
  filtersParams,
  setFiltersParams,
  setTriggerSubmit,
}) => {
  const handleReset = () => {
    // Reset all filters, keep language and page size, reset offset.
    // All "attribute" filters and workload are removed
    setFiltersParams({
      lang: filtersParams.lang,
      limit: filtersParams.limit,
      offset: 0,
    });
    setTriggerSubmit(true); // reset button needs to always trigger a reload directly
  };

  return (
    <>
      <Link
        className="reset-filters-button"
        variant="text"
        onClick={handleReset}
      >
        {i18n.translations.resetFilters}
      </Link>
    </>
  );
};

export default ResetFiltersButton;
