import * as React from "react";
import { useSettings } from "../util/SettingsContext";
import Pagination from '@mui/material/Pagination';
import { i18n } from "../util/i18n";
import { Button, Grid, Link } from "@mui/material";

import PropTypes from "prop-types";
import { PaginationProps } from "@mui/material";

/**
 * This is a reusable pagination component for Job Lists.
 * It uses the filtersParams object to get the current offset and limit (= page size) and also the total number of jobs for the query.
 * It displays a Pagination control from Material UI.
 * If you pass in a setFilterParams function, it will be called when the user clicks on a page number with the updated offset property
 * (so that you can then fetch the new page of jobs).
 *
 * @param {} filtersParams The current filtersParams object. Important properties: offset, limit
 * @param {*} setFilterParams A useState setter function for the filtersParams object
 * @param {*} totalJobs Total number of jobs for the query. This is used to calculate the number of pages.
 * @param {*} loading if True the component will display a loading indicator instead of the pagination control
 * @param {*} props Will be spread onto the Pagination component. You can use this e.g. for custom styling via sx={{...}} property.
 *
 * @returns JobListPagination component
 */
const JobListLoadMore = ({
  filtersParams,
  setFiltersParams,
  totalJobs,
  loading = false,
  ...props
}) => {
  const {settings, setSettings} = useSettings();

  const currentPage = filtersParams?.offset / filtersParams?.limit + 1;
  const totalPages = Math.ceil(totalJobs / filtersParams?.limit);

  let empty = !(totalPages >= 1);

  let error =
    !filtersParams ||
    !setFiltersParams ||
    filtersParams.offset < 0 ||
    filtersParams.limit <= 0 ||
    filtersParams.offset > totalJobs;

  const containerRef = React.useRef(); // Ref to the container element

  const handleLoadMore = () => {

    //console.log("Going to page:", actualPage);
    setFiltersParams({
      ...filtersParams,
      offset: 0,
      limit: filtersParams.limit + settings.jobsPageSize,
      submitted: true,
    });

  }

  return (
    <>
      {error && (
        <div>
          ERROR: Invalid or missing required properties for JobListPagination
        </div>
      )}
      {(loading || empty)}

      {/* Only render the load more button when there are more pages AND more items in the current page */}
      {totalPages >= 1 && currentPage < totalPages && (
          <div ref={containerRef}>
            <Grid container item justifyContent="start" alignItems="start" className="load-more-container">
              <Link
                  className="load-more"
                  onClick={handleLoadMore}
              >
                {i18n.translations.loadmorejobs}
              </Link>
            </Grid>
          </div>
      )}
    </>
  );
};

export default JobListLoadMore;