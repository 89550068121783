// Custom hook: useUrlState.js
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";

// todo initalize from settings
const DEFAULT_FILTERS_PARAMS = {
  lang: "de",
  q: "",
  limit: 10,
  offset: 0,
  workload: [0, 100],
  f: {},
};

/**
 * Convert url query string to filtersParams. FiltersParams are used as global filter
 * state that is modified by the user using the filter components.
 * Example: ?lang=de&q=Arzt&limit=10&offset=0&workload=40,100&f=80:1111,2222&f=90:3333?lang=de&q=Arzt&limit=10&offset=3&workload=40,100&f=80:1111,2222&f=90:3333
 * Should return : {
 * lang: "de",
 * q: 'Arzt',
 * limit: 10,
 * offset: 0,
 * workload: [40, 100],
 * f: {
 * 80: ['1111', '2222'],
 * 90: ['3333'],
 * }
 * @param {*} urlParams
 * @param {*} attributes
 * @param {*} defaultFiltersParams
 * @returns object, the filtersParams
 */
export const urlToFiltersParams = (
  urlQuery,
  attributes,
  defaultFiltersParams = DEFAULT_FILTERS_PARAMS
) => {
  // Use qs.parse to parse the entire input string into an object
  const parsedInput = qs.parse(urlQuery, {
    ignoreQueryPrefix: true,
    delimiter: "&",
    arrayLimit: Infinity,
  });
  const parseValues = (values) => {
    return values.split(",");
  };

  const processFAttribute = (attributes, ids) => {
    const result = {};

    ids.forEach((idValues, index) => {
      const [id, values] = idValues.split(":");
      const valueList = values.split(",");

      valueList.forEach((value) => {
        attributes.forEach((attribute) => {
          if (attribute.values?.[value]) {
            result[attribute.id] = result[attribute.id] || [];
            result[attribute.id].push(value);
          }
        });
      });
    });

    // Convert the arrays in the result to comma-separated strings
    Object.keys(result).forEach((key) => {
      result[key] = result[key].join(",");
    });

    return result;
  };

  // Process the f attribute and store it in the parsedInput object
  const newF = processFAttribute(attributes, parsedInput.f);

  const filtersParams = {
    ...defaultFiltersParams,
    ...parsedInput,
    offset: parsedInput.offset
      ? Number(parsedInput.offset)
      : defaultFiltersParams.offset,
    limit: parsedInput.limit
      ? Number(parsedInput.limit)
      : defaultFiltersParams.limit,
    workload: parsedInput.workload
      ? parsedInput.workload.split(",").map(Number)
      : defaultFiltersParams.workload,
    f: { ...newF },
  };

  return filtersParams;
};

/**
 * Convert filtersParams to urlParams, to be used for the url state, and for the jobs API query.
 * 
 * Unfortuantely, the API has a bit of a weird format for the f parameter, so we need to do some special handling here.
 * All attribute values have to be attached to the top level filter parameter,
 *       f: {
        "10_1446684": "1446685,1446686",
        10: "1446708",
        20: "1479772",
      },
      should become f=10:1446685,1446686,1446708&f=20:1479772
 *
 * @param {*} filtersParams
 * @returns string (not url encoded), containing the url query params including the query prefix ("?").
 */
export const filtersParamsToUrl = (filtersParams) => {
  const params = { ...filtersParams };
  let fParamString = "";
  // convert workload from array to string
  if (params.workload) {
    params.workload = params.workload.join(",");
  }
  // convert f from object to array.
  // this needs special handling,
  if (params.f) {
    // create a uniqueF object with values under top-level filterId {10: [1446685,1446686, 1446708], 20: [1479772]}
    // Note that the values are sorted ascending (e.g. not necessarly in the same relative order than in the filtersParams)
    const uniqueF = {};
    for (const [filterId, valuesString] of Object.entries(params.f)) {
      const [id, childrenId] = filterId.split("_");
      const values = valuesString.split(",").map(Number);
      if (!uniqueF[id]) {
        uniqueF[id] = [];
      }
      uniqueF[id].push(...values);
    }
    for (const id in uniqueF) {
      uniqueF[id].sort((a, b) => a - b);
    }

    // convert uniqueF to string 'f=10:1446685,1446686,1446708&f=20:1479772'
    fParamString = Object.entries(uniqueF)
      .map(([id, values]) => `f=${id}:${values.join(",")}`)
      .join("&");
    delete params.f; // remove f from params
  }

  // create url query string. Don't encode here.
  let urlString = qs.stringify(params, { addQueryPrefix: true, encode: false });

  if (fParamString) {
    urlString += "&" + fParamString;
  }
  return urlString;
};

/**
 * Converts the filtersParams to a list of options for the Select component.
 * The options have the following structure:
 * {
 * '80': [{
 *  filter_id: 80,
 *  value: '1111,2222',
 *  label: 'Parent option',
 *  has_children: true,
 *  children_filter_id: '80_1111'
 * }, {
 * filter_id: '80_1111',
 * value: '1111',
 * label 'Child option 1',
 * has_children: false,
 * children_filter_id: null
 * }, {
 * filter_id: '80_2222',
 * value: '2222',
 * label 'Child option 2',
 * has_children: false,
 * children_filter_id: null
 * }],
 * '90': ...
 * }
 *
 * @param {*} filtersParams
 * @param {*} attributes The job attributes, used to lookup relevant filter options (not all attributes are filter options)
 * @returns string, containing the url params including the query prefix ("?").
 */
export const filtersParamsToOptions = (filtersParams, attributes) => {
  const options = {};

  filtersParams = { ...filtersParams, f: { ...filtersParams.f } };

  Object.keys(filtersParams.f).forEach((filterId) => {
    const selectedValues = filtersParams.f[filterId].split(",");

    //filterId = filterId.split("_")[0];
    options[filterId] = options[filterId] || [];

    selectedValues.forEach((value) => {
      // go over all selected option values and find the corresponding attribute
      // value e.g. 1446685
      const result = attributes.find((attribute) => {
        if (attribute?.values?.[value]) {
          return true;
        }
        return false;
      });

      if (result) {
        const option = {
          filter_id: result.id,
          value: value,
          label: result.values[value],
          children_filter_id: `${result.id}_${value}`,
          has_children: false,
          // NOTE that options are always the leaf nodes, so we should never see "parents" here.
          // more exact would be to see if the children_filter_id exists in attributes, because in that case
          // we have a parent node.
        };

        options[filterId].push(option);
        // Sort the options[filterId] list in ascending order by the "value" property
        options[filterId].sort((a, b) => {
          return a.value - b.value;
        });
      }
    });
  });

  return options;
};

/**
 * Convert the options object to a filtersParams object.
 * If we pass in a currentFiltersParams object,
 * we will merge the options into that object (e.g. only filter options under "f" will be updated)
 * @param {*} selected_options:  an object with filterId as key and an array of options as value: {10: [{...},{...}]}
 * @param {*} currentFiltersParams
 */
export const optionsToFiltersParams = (
  selected_options,
  currentFiltersParams = {}
) => {
  let selected = selected_options;
  const filtersParams = {
    ...currentFiltersParams,
    f: {
      ...currentFiltersParams.f,
    },
  };

  Object.keys(selected).forEach((topLevelfilterId) => {
    if (selected[topLevelfilterId].length == 0) {
      delete filtersParams.f[topLevelfilterId];
      return;
    }
    selected[topLevelfilterId].map((option) => {
      if (!option.has_children) {
        filtersParams.f[option.filter_id] =
          filtersParams.f[option.filter_id] || [];

        // If the option is already in the list, remove it
        if (!Array.isArray(filtersParams.f[option.filter_id])) {
          filtersParams.f[option.filter_id] = [];
        }
        filtersParams.f[option.filter_id].push(option.value);
        filtersParams.f[option.filter_id].sort();
      }
    });
  });

  // /**
  //  * If we passed a array of options (like from a Select component), use the filetrId of the first element
  //  */
  // if (typeof selected_options === "object") {
  //   // We just assume that the object is in the correct format
  //   selected = selected_options;
  // } else {
  //   console.error(
  //     "optionsToFiltersParams: invalid selected_options",
  //     selected_options
  //   );
  //   return filtersParams;
  // }

  // console.log("optionsToFiltersParams: selected", selected);
  // console.log("optionsToFiltersParams: filtersParams", filtersParams);

  // Object.keys(selected).forEach((filterId) => {
  //   const current = filtersParams.f[filterId]?.split(",") || [];
  //   const attrStr = selected[filterId]
  //     .filter((option) => !option.has_children)
  //     .map((option) => option.value);

  //   filtersParams.f[filterId] = attrStr.join(",");
  // });
  // console.log("optionsToFiltersParams: new filtersParams", filtersParams);

  Object.keys(filtersParams.f).map((key) => {
    if (Array.isArray(filtersParams.f[key])) {
      filtersParams.f[key] = filtersParams.f[key].join(",");
    }
  });
  return filtersParams;
};

/**
 * List of options for a particular filterId. If the filterId is not found, an empty array is returned.
 *
 * @param {*} filterId
 * @param {*} attributes
 * @returns
 */
const attributesFilterIdToOptions = (filterId, attributes) => {
  let options = [];
  const attribute = attributes.find((attribute) => attribute.id === filterId);
  if (!attribute) {
    return [];
  }

  attribute.sorted_values?.forEach((item) => {
    options.push({
      filter_id: attribute.id,
      value: item.id,
      label: item.name,
      has_children:
        attributesFilterIdToOptions(`${attribute.id}_${item.id}`, attributes)
          ?.length > 0
          ? true
          : false,
      children_filter_id: `${attribute.id}_${item.id}`,
    });
  });

  return options;
};

/**
 * Attributes are a list of available filter options:
 * [{id: "80",
 *   name: "Parent Option",
 *   sorted_values: [
 *     {id: "1111", name: "Child Option 1"},
 *     {id: "2222", name: "Child Option 2"}]
 *  },
 *  {id: "90",
 *   name: "Standalone Option", sorted_values: [{id: "3333", name: "..."}, {id: "4444", name: "..."}]}]
 *
 * @param {*} attributes
 * @returns array of `FilterOption` objects.
 */
const attributesToOptions = (attributes, topLevelOnly = false) => {
  let options = {};
  attributes.forEach((attribute) => {
    options[attribute.id] = [];

    attribute.sorted_values?.forEach((item) => {
      let children = attributesFilterIdToOptions(
        `${attribute.id}_${item.id}`,
        attributes
      ); // get children, if any

      options[attribute.id].push({
        filter_id: attribute.id,
        value: item.id,
        label: item.name,
        has_children: children?.length > 0 ? true : false,
        children_filter_id: `${attribute.id}_${item.id}`,
      });

      // append the children to the options array.
      // So we always keep the order: First the "parent", then a list of children.
      // If we request "topLevelOnly", we will not add the children.
      if (children?.length > 0 && !topLevelOnly) {
        options[attribute.id] = options[attribute.id].concat(children);
      }
    });
  });
  return options;
};

/**
 * Returns all options for a given filterId as an array of Option objects.
 * This should be directly usable for the Select / Autocomplete component.
 * It contains all eleemnts in the right order, groups/parents and children.
 * The Select Component will need to implement styling and logic, e.g click on a parent will toggle all children etc.
 * @param {*} filterId
 * @param {*} attributes
 * @returns
 */
export const getAllOptionsForFilter = (
  filterId,
  attributes,
  topLevelOnly = false
) => {
  return attributesToOptions(attributes, topLevelOnly)[filterId];
};
