import React, { useEffect, useState } from "react";
import { i18n } from "../util/i18n";
import {Button, TextField, Box, IconButton, FormControl, InputLabel, Input, Paper} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useDebounce } from "ahooks";
import { useSettings } from "../util/SettingsContext";
import { useFiltersParams } from "../util/FiltersParamsContext";

/**
 * A React component that controls the text search filter.
 * It supports debouncing, so the filtersParams (passed down by the parent) are only updated after a period of time.
 * The setting to control this is settings.textSearchFilterDebounceTime
 */

const TextSearchFilterInput = ({
  label,
  alignIcon,
  filtersParams,
  setFiltersParams,
  ...props
}) => {
  const { settings } = useSettings();
  //const { filtersParams, setFiltersParams } = useFiltersParams();

  // We use a local state to store the current filter state.
  // The debouncing will update te setFilterParams only after a period of time
  const [internalFilterState, setInternalFilterState] = useState(filtersParams);

  // we want to debounce the text search input, so we use the "useDebounce" hook from "ahooks"
  // see: https://ahooks.js.org/hooks/use-debounce
  const debouncedFilterState = useDebounce(internalFilterState, {
    wait: settings.textSearchFilterDebounceTime || 500,
  });

  /**
   * Handle the change of the text search input field.
   * We create a new filtersParams object and update the local state.
   * At this point, the (global) filtersParams are not updated yet.
   */
  const handleTextSearchChange = (event) => {
    const newFilterParams = {
      ...filtersParams, // keep the existing filter params
      q: event.target.value, // set "q" param to the new value
      offset: 0, // always clear offset when changing filters
    };
    // update our internal state
    console.log("newFilterParams", newFilterParams);
    setInternalFilterState(newFilterParams);
  };

  // ONLY after the debounced filter state has changed, we update the (global) filter params
  useEffect(() => {
    setFiltersParams(debouncedFilterState);
  }, [debouncedFilterState]);

  // if the filtersParams change, we update our internal state (e.g. when the user clicks "reset")
  useEffect(() => {
    setInternalFilterState(filtersParams);
  }, [filtersParams]);

  return (
    <>
      <Paper
          className="search"
          sx={{ display: 'flex', alignItems: 'center', ...props.sx }}
          elevation={0}
      >
        {alignIcon == "start" &&
            <IconButton type="button" aria-label="search">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.295 14.0283C11.8843 15.2748 10.0305 16.0312 8 16.0312C3.58172 16.0312 0 12.4495 0 8.03125C0 3.61297 3.58172 0.03125 8 0.03125C12.4183 0.03125 16 3.61297 16 8.03125C16 10.0592 15.2454 11.9109 14.0017 13.3209L19.8536 19.1727L19.1465 19.8798L13.295 14.0283ZM15 8.03125C15 11.8972 11.866 15.0312 8 15.0312C4.13401 15.0312 1 11.8972 1 8.03125C1 4.16526 4.13401 1.03125 8 1.03125C11.866 1.03125 15 4.16526 15 8.03125Z" fill="black"/>
              </svg>
            </IconButton>
        }
        <TextField
            className={alignIcon}
            fullWidth
            onChange={handleTextSearchChange}
            placeholder={""}
            variant="standard"
            value={internalFilterState?.q ? internalFilterState.q : ""}
            label={label}
        />
        {alignIcon == "end" &&
            <IconButton type="button" aria-label="search">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.295 14.0283C11.8843 15.2748 10.0305 16.0312 8 16.0312C3.58172 16.0312 0 12.4495 0 8.03125C0 3.61297 3.58172 0.03125 8 0.03125C12.4183 0.03125 16 3.61297 16 8.03125C16 10.0592 15.2454 11.9109 14.0017 13.3209L19.8536 19.1727L19.1465 19.8798L13.295 14.0283ZM15 8.03125C15 11.8972 11.866 15.0312 8 15.0312C4.13401 15.0312 1 11.8972 1 8.03125C1 4.16526 4.13401 1.03125 8 1.03125C11.866 1.03125 15 4.16526 15 8.03125Z" fill="black"/>
              </svg>
            </IconButton>
        }
      </Paper>
    </>
  );
};

export default TextSearchFilterInput;
