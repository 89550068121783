import React from "react";
import { useFiltersParams } from "./util/FiltersParamsContext";
import { useFilters } from "./util/FiltersContext";
import ErrorPage from "./ErrorPage";
import JobAboLayout from "./jobabo/layout";

const JobAboPage = () => {
  const {
    loading: isFiltersLoading,
    error: isFiltersError,
    data: filtersData,
  } = useFilters();
  const { filtersParams, setFiltersParams } = useFiltersParams();

  return (
    <>
      {isFiltersLoading}
      {isFiltersError && <ErrorPage />}
      {filtersData && <JobAboLayout />}
    </>
  );
};

export default JobAboPage;
