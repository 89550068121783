import React from "react";
import {
  Card,
  CardContent, Fade,
  Grid,
  List,
  ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from "@mui/material";
import {useSettings} from "../util/SettingsContext";
import Favourite from "./Favourite";
import FolderIcon from "@mui/icons-material/Folder";
import {JobGridItemSkeleton, JobListItemSkeleton} from "../careercenter/jobItemSkeleton";

/**
 * Renders a list of jobs, based on the JSON object passed in the props.
 * Uses MUI List components, see: https://mui.com/material-ui/react-list/
 *
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobList
 */
const JobList = ({
  jobsData,
  jobPageSize,
  renderJobItem = (job) => {
    <Typography key={job.id}>{job.title}</Typography>;
  },
  notFoundComponent = <Typography>Sorry, no Jobs found</Typography>,
  loading = false,
  disableSkeleton = false,
  displayAsGrid = false,
  children,
  ...props
} = props) => {
  const { settings } = useSettings();
  const jobList = jobsData?.jobs ? jobsData.jobs : [];

  if (!Array.isArray(jobList)) {
    return (
      <div key="invalid-jobs">
        Invalid jobs list, expected an array of job objects
      </div>
    );
  }

  if (loading || (!jobsData && !disableSkeleton)) {
    return (
        <>
          {!displayAsGrid && (
              <List className="job-list">
                {new Array(settings.jobsPageSize).fill({}).map((item, idx) => (
                    <JobListItemSkeleton
                        key={`skeleton-${idx}`}
                    />
                ))}
              </List>
          )}
          {displayAsGrid && (
              <Grid className="job-grid" container spacing={3}>
                {new Array(settings.jobsPageSize).fill({}).map((item, idx) => (
                    <JobGridItemSkeleton
                        key={`skeleton-${idx}`}
                    />
                ))}
              </Grid>
          )}
        </>


    );
 }

  return (
    <>
      {jobList.length > 0 && !displayAsGrid && (
        <List className="job-list">
          {jobList.map((job) => {
            return renderJobItem(job);
          })}
        </List>
      )}
      {jobList.length > 0 && displayAsGrid && (
        <Grid className="job-grid" container justifyContent='stretch' alignItems='stretch'  spacing={3}>
          {jobList.map((job) => {
            return renderJobItem(job);
          })}
        </Grid>
      )}

      {jobList.length == 0 && (
        <>{notFoundComponent}</>
      )}
    </>
  );
};

export default JobList;
