import {
  Autocomplete,
  Badge,
  Checkbox,
  TextField,
} from "@mui/material";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const dropdownIcon = <ExpandMoreIcon fontSize="large"/>;

/**
 * A flexible Autocomplete component for Attribute filters.
 * Supports single or multiple selections.
 * For multiple selections, the values are joined with a comma.
 */

/**
 * This converts the project filter attributes into a format that can be used with our Autocomplete.
 * It will return a flat list, where hierarchical filters get a "group=true" property, and the value is the string concatenation of all child ids.
 * For all non-hierarchical filters, the value is the id of the attribute.
 */
const convertToOptionsList = (attributes, attributeId) => {
  //console.info("--- Attributes: ", attributes);

  let options = [];
  let sorted_values = [];
  let topLevelOptions = attributes
      .filter((item) => item.id == attributeId)
      .map((attribute) => attribute.sorted_values)[0];

  console.log("Top Level Options", topLevelOptions);

  topLevelOptions.map((topLevelOption) => {
    let option = {
      id: topLevelOption["id"],
      label: topLevelOption["name"],
      value: topLevelOption["id"],
    };

    // push the top level option
    options.push(option);

    let childAttributes = attributes.find(
        (item) => item.parent_value_id == option.id
    )?.sorted_values;
    // do we have child attributes?
    if (childAttributes) {
      option.value = ""; // For groups, value should only include ids of children
      //console.log("--- Child Attributes for ", option.label, childAttributes);
      childAttributes.map((childAttribute) => {
        //console.log("Child Attribute", childAttribute);

        // append to parent option value
        option.group = true;
        option.value = option.value
            ? `${option.value},${childAttribute["id"]}`
            : childAttribute["id"];

        options.push({
          id: childAttribute["id"],
          label: childAttribute["name"],
          value: childAttribute["id"],
          parent: option.id,
        });
      });
    }
  });

  console.log("All available options", options);
  return options;
};

/**
 * Finds the Options within the options list that match the current filter value.
 * The selectedIdValues are a string of comma-separated ids.
 *
 * @param {*} allOptions
 * @param {*} selectedIdValues
 */
const getSelectedOptions = (allOptions, selectedIdValues) => {
  if (!selectedIdValues) {
    return [];
  }
  let selectedOptions = [];
  console.log("---", typeof selectedIdValues);

  selectedIdValues.split(",").map((id) => {
    let option = allOptions.find((item) => item.id == id);
    if (option) {
      selectedOptions.push(option);
    }
  });

  console.log("selected", selectedOptions);

  return selectedOptions;
};

const AttributeFilterAutocomplete = ({
                                       filtersParams,
                                       setFiltersParams,
                                       attributes,
                                       filterAttributeId,
                                       label = "Filter",
                                       placeholder,
                                       ...props
                                     }) => {
  if (!attributes) {
    return <div>Project Attributes are required</div>;
  }

  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }
  const filterId = filterAttributeId;
  //const filterName = "Klinik / Institut";
  const multiple = true;

  // Convert the attributes for this filter into a flat list of options
  const options = convertToOptionsList(attributes, filterId);
  // Now check which of these options are currently selected (by looking at the filter params)
  let selectedOptions = getSelectedOptions(
      options,
      filtersParams["f"] ? filtersParams["f"][`${filterId}`] : ""
  );

  const handleChanged = (event, value) => {
    //console.log("Filter has changed, updating...", event);
    // keep all filter values, but change ONLY the one that this component is responsible for.
    // Reset offset to zero.
    console.log("Value changed", value);

    let filterAttributeList = value
        //.filter((v) => v.group == false) // don't add the group to the filter selection
        .map((o) => o.value) // o.value is a comma separated string of ids, or a single id
        .join(",") // make one longe string of comma separated ids
        .split(","); // and split again into an array

    console.log("Before normalize", filterAttributeList);

    // Little trick: all ids that are duplicate now, should be removed (= toggled)
    // filterAttributeList = filterAttributeList.filter(() => {});
    filterAttributeList = filterAttributeList.filter((id) => {
      return (
          filterAttributeList.indexOf(id) === filterAttributeList.lastIndexOf(id)
      );
    });

    console.log("Final normalize", filterAttributeList);

    let newFilterParams = {
      ...filtersParams,
      f: {
        ...filtersParams.f,
        [`${filterId}`]: filterAttributeList.join(","),
      },

      offset: 0,
    };

    console.log("Setting new filter params", value, newFilterParams);

    setFiltersParams(newFilterParams);
  };

  // see: https://mui.com/material-ui/api/autocomplete/
  return (
      <>
        <Autocomplete
            id={`attribute-filter-${filterId}`}
            fullWidth={true}
            limitTags={3}
            multiple={multiple}
            popupIcon={dropdownIcon}
            value={selectedOptions}
            onChange={handleChanged}
            componentsProps={{}}
            options={options}
            renderTags={(value, getTagProps, ownerState) => {
              return (
                  <Badge
                      sx={{mx: 0}}
                      badgeContent={ownerState.value.length}
                  >
                  </Badge>
              );
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.label}
            isOptionEqualToValue={(option, value) => {
              // to tell the component which options are selected, need to match ids of filter option with current state
              return option.id == value.id;
            }}
            renderOption={(props, option, {selected}) => (
                <li {...props} className={option.parent ? "child" : "parent"}>
                  <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      sx={
                        option.parent
                            ? {marginLeft: -1, marginRight: 0, color: "red"}
                            : {marginRight: -4}
                      }
                      value={option.id}
                      checked={selected}
                  />
                  {option.label}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={""}
                    placeholder={placeholder || label}
                    variant="standard"
                />
            )}
        />
      </>
  );
};

export default AttributeFilterAutocomplete;