import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
    HashRouter as HeaderHashRouter,
    Link as RouterLink,
    Outlet,
    Route,
    Routes,
    Link,
    useNavigate
} from "react-router-dom"; // Import necessary components
import {
    Card,
    CardContent, Fade,
    Grid,
    MenuList,
    MenuItem,
    Drawer,
    IconButton,
    List,
    ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText,
    ListSubheader,
    Skeleton,
    Typography, Stack,
} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { i18n } from "../util/i18n";
import companyLogo from "../../public/images/logo-raiffeisen.svg"

import CloseIcon from '@mui/icons-material/Close';
import CareerCenterPage from "../CareerCenterPage";
import JobAboPage from "../JobAboPage";
import PersonalvermittlerPage from "../PersonalvermittlerPage";

const Header = ({
                    loading = false,
                    ...props
                } = props) => {
    const [open, setOpen] = React.useState(false);
    const handleToggle = () => setOpen(!open);
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const location = useLocation();

    const navigate = useNavigate(); // Import useNavigate from 'react-router-dom'

    return (
        <header>
            <Grid
                className="header-container"
                container
                direction="row"
                justifyContent="space-between"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
            >
                <Grid item className="logoHolder">
                    <a href={i18n.translations.headerLogoLink1Url} title={i18n.translations.headerLogoTitle}>
                        <img alt="Logo" src={companyLogo} />
                    </a>
                </Grid>

                <Grid item className="primaryMenu">

                    <List role="menubar" orientation="horizontal" component="nav" className="desktopNav" sx={{ display: { xs: 'none', sm: 'none' , md: 'flex' } }}>
                        <ListItem role="none">
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                href={i18n.translations.headerNavLink1Url}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/");
                                }}
                                className={location.pathname === "/" ? "anchorActive" : ""}
                            >
                                <span>{i18n.translations.headerNavLink1Label}</span>
                            </ListItemButton>
                        </ListItem>
                        <ListItem role="none">
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                href={i18n.translations.headerNavLink2Url}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/personalvermittler");
                                }}
                                className={location.pathname === "/personalvermittler" ? "anchorActive" : ""}
                            >
                                <span>{i18n.translations.headerNavLink2Label}</span>
                            </ListItemButton>
                        </ListItem>
                        <ListItem role="none">
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                href={i18n.translations.headerNavLink3Url}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/jobabo");
                                }}
                                className={location.pathname === "/jobabo" ? "anchorActive" : ""}
                            >
                                <span>{i18n.translations.headerNavLink3Label}</span>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <IconButton onClick={handleDrawerOpen} sx={{ display: { sm: 'block' , md: 'none' } }}>
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-0.000976562 8H16.999V10H-0.000976562V8Z" fill="#1A1A1A"/>
                            <path d="M-0.000976562 0H19.999V2H-0.000976562V0Z" fill="#1A1A1A"/>
                        </svg>
                    </IconButton>
                    <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => open && handleDrawerClose()}
                    >
                        <Drawer
                            className="mobileMenu"
                            anchor="right"
                            open={open}
                            onClose={(_, reason) => reason === 'backdropClick' && setOpen(false)}
                            PaperProps={{
                                sx: { width: "320px" },
                            }}
                        >
                            <Stack direction={"column"} spacing={2}>
                                <div className="navigation-drawer-menu">
                                    <IconButton onClick={handleDrawerClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <div className="navigation-drawer-menu-body">
                                    <List role="menubar" orientation="horizontal" component="nav" className="mobileNav">
                                        <ListItem role="none">
                                            <ListItemButton
                                                role="menuitem"
                                                component="a"
                                                href={i18n.translations.headerNavLink1Url}
                                                title={i18n.translations.headerNavLink1Label}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/");
                                                    handleDrawerClose();
                                                }}
                                                className={location.pathname === "/" ? "anchorActive" : ""}
                                            >
                                                {i18n.translations.headerNavLink1Label}
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem role="none">
                                            <ListItemButton
                                                role="menuitem"
                                                component="a"
                                                href={i18n.translations.headerNavLink2Url}
                                                title={i18n.translations.headerNavLink2Label}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/personalvermittler");
                                                    handleDrawerClose();
                                                }}
                                                className={location.pathname === "/personalvermittler" ? "anchorActive" : ""}
                                            >
                                                {i18n.translations.headerNavLink2Label}
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem role="none">
                                            <ListItemButton
                                                role="menuitem"
                                                component="a"
                                                href={i18n.translations.headerNavLink3Url}
                                                title={i18n.translations.headerNavLink3Label}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/jobabo");
                                                    handleDrawerClose();
                                                }}
                                                className={location.pathname === "/jobabo" ? "anchorActive" : ""}
                                            >
                                                {i18n.translations.headerNavLink3Label}
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </div>
                            </Stack>
                        </Drawer>
                    </ClickAwayListener>
                </Grid>
            </Grid>
        </header>
    );
};

export default Header;