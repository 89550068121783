export default {
  de: {
    appTitle: "Raiffeisen Jobbörse",
    appTitleSeparator: "–",
    appTitleAddition: "Wir machen den Weg frei",
    jobboerseIntroText1: "Erfahren Sie unter",
    jobboerseIntroLinkLabel: "Bewerbungsprozess & Tipps",
    jobboerseIntroLinkUrl: "https://www.raiffeisen.ch/rch/de/ueber-uns/karriere-stellen/bewerbungsprozess-und-kontakt/bewerbungsprozess-tipps.html",
    jobboerseIntroText2: ", welche Schritte nach der Einreichung Ihrer Online-Bewerbung auf Sie zukommen und wie Ihr Weg zu uns aussieht.",
    searchPlaceholder: "Suchbegriff eingeben",
    searchPlaceholderJobabo: "Suchbegriff (optional)",
    filtersTitle: "Jobsuche",
    showFilters: "Filter",
    hideFilters: "Filter ausblenden",
    resetFilters: "Filter zurücksetzen",
    submitFilters: "Übernehmen",
    inputSelectPlaceholder: "Alle",
    noJobs: "Es wurden keine Stelleninserate gefunden.",
    jobaboModalButtonLabel: "JobAbo erfassen",
    filterBeschäftigungsartLabel: "Ich interessiere mich für",
    filterFachbereichLabel: "Fachbereich",
    filterPensumLabel: "Pensum",
    filterRegionLabel: "Region",
    filterFührungsfunktionLabel: "Mit Führungsverantwortung",
    personalvermittlerPageTitle: "Personal\u00ADvermittler",
    personalvermittlerPageIntroText: "Informationen für Personalvermittlungen",
    personalvermittlerPageContentText1: "Geschätzte Personalvermittler",
    personalvermittlerPageContentText2: "Wir arbeiten mit ausgewählten Personalvermittlungen zusammen, die unsere Vertragsbestimmungen für Personalvermittlungen auf Erfolgshonorarbasis unterzeichnet haben. Derzeit nehmen wir keine weiteren Personalvermittlungen auf.",
    personalvermittlerPageContentText3: "Wir bitten um Verständnis.",
    personalvermittlerPageContentText4: "Freundliche Grüsse",
    personalvermittlerPageContentText5: "HRM Raiffeisen Schweiz, Tel. 071 225 89 28",
    personalvermittlerButtonLabel: "Zugang Personalvermittler",
    personalvermittlerButtonUrl: "https://wd3.myworkday.com/wday/authgwy/raiffeisen/login.htmld?redirect=n",
    jobaboPageTitle: "JobAbo erfassen",
    jobaboPageIntroText: "Wollen Sie regelmässig über neue Stellenangebote bei Raiffeisen informiert werden? Eröffnen Sie Ihr individuelles Stellensuchprofil und Sie werden automatisch über passende Stellenangebote informiert.",
    jobaboEmailLabel: "E-Mail",
    emailFieldValidationText: "Bitte geben Sie eine gültige Email-Adresse ein",
    jobaboNameLabel: "JobAbo Titel",
    nameFieldValidationText: "Bitte füllen Sie dieses Pflichtfeld aus",
    jobaboSubscriptionFormTitle: "JobAbo",
    jobaboSuccessText1: "Das Formular wurde erfolgreich versendet! Sie erhalten in Kürze eine Bestätigungsmail.",
    jobaboSuccessText2: "Bitte bestätigen Sie Ihre Anmeldung zum JobAbo.",
    displayAsGrid: "Als Gitter anzeigen",
    jobGridColumns: "Anzahl Spalten",
    error: "Leider ist beim Laden der Seite ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    loadmorejobs: "Mehr anzeigen",
    headerLogoTitle: "Raiffeisen Schweiz",
    headerLogoLink1Url: "https://www.raiffeisen.ch/rch/de.html",
    headerNavLink1Label: "Jobbörse",
    headerNavLink1Url: "https://jobs.raiffeisen.ch/",
    headerNavLink2Label: "Personalvermittler",
    headerNavLink2Url: "/personalvermittler",
    headerNavLink3Label: "JobAbo",
    headerNavLink3Url: "/jobabo",
    footerTopLink1Label: "Karriere",
    footerTopLink1Url: "https://www.raiffeisen.ch/rch/de/ueber-uns/karriere.html",
    footerTopLink2Label: "Arbeiten bei Raiffeisen Schweiz",
    footerTopLink2Url: "https://www.raiffeisen.ch/rch/de/ueber-uns/karriere/arbeiten-bei-raiffeisen-schweiz.html",
    footerTopLink3Label: "Arbeiten bei einer Raiffeisenbank",
    footerTopLink3Url: "https://www.raiffeisen.ch/rch/de/ueber-uns/ihre-bank-vor-ort.html",
    footerTopLink4Label: "Ausbildung bei Raiffeisen",
    footerTopLink4Url: "https://www.raiffeisen.ch/rch/de/ueber-uns/karriere/ausbildung-bei-raiffeisen.html",
    footerNavLink1Label: "Nutzungsbedingungen",
    footerNavLink1Url: "https://www.raiffeisen.ch/rch/de/ueber-uns/raiffeisen-gruppe/disclaimer-website/nutzungsbedingungen-fuer-unsere-webseiten.html",
    footerNavLink2Label: "Impressum & Disclaimer",
    footerNavLink2Url: "https://www.raiffeisen.ch/rch/de/ueber-uns/raiffeisen-gruppe/disclaimer-website.html",
    footerNavLink3Label: "Cookie Einstellungen",
    footerCopyright: "© Raiffeisen Schweiz",
    footerSocialFacebookUrl: "https://www.facebook.com/RaiffeisenMemberPlus.Schweiz/",
    footerSocialTwitterUrl: "https://www.twitter.com/raiffeisen_ch",
    footerSocialLinkedinUrl: "https://www.linkedin.com/company/raiffeisen-switzerland",
    footerSocialInstagramUrl: "https://www.instagram.com/raiffeisen_youngmember_d/",
  },


  fr: {
    appTitle: "Portail Emploi du Groupe Raiffeisen",
    appTitleSeparator: "–",
    appTitleAddition: "Ouvrons la voie",
    jobboerseIntroText1: "Dans l’onglet",
    jobboerseIntroLinkLabel: "Processus de candidature et conseils",
    jobboerseIntroLinkUrl: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/carriere-postes/processus-de-candidature-et-contact/processus-de-candidature-et-conseils.html",
    jobboerseIntroText2: ", vous trouverez les étapes qui vous attendent une fois que vous aurez soumis votre candidature en ligne et le parcours qui vous mène jusqu’à nous.",
    searchPlaceholder: "Saisir le critère de recherche",
    searchPlaceholderJobabo: "Critère de recherche (facultatif)",
    filtersTitle: "Recherche d'emploi",
    showFilters: "Filtre",
    hideFilters: "Masquer le filtre",
    resetFilters: "Réinitialiser le filtre",
    submitFilters: "Appliquer",
    inputSelectPlaceholder: "Tous",
    noJobs: "Il n'y a pas d'annonces emploi.",
    jobaboModalButtonLabel: "Créer un abonnement de recherche d’emploi",
    filterBeschäftigungsartLabel: "Je suis intéressé par",
    filterFachbereichLabel: "Secteur spécialisé",
    filterPensumLabel: "Taux d'occupation",
    filterRegionLabel: "Région",
    filterFührungsfunktionLabel: "avec responsabilité de direction",
    personalvermittlerPageTitle: "Recruteur",
    personalvermittlerPageIntroText: "Information pour les agences de placement de personnel",
    personalvermittlerPageContentText1: "Madame, Monsieur,",
    personalvermittlerPageContentText2: "Nous collaborons avec des agences de placement de personnel sélectionnées qui ont signé nos dispositions contractuelles relatives au placement de personnel sur la base d'honoraires pour les placements réussis. Nous n'acceptons plus actuellement d'autres agences de placement de personnel.",
    personalvermittlerPageContentText3: "Nous vous remercions de votre compréhension.",
    personalvermittlerPageContentText4: "Avec nos meilleures salutations",
    personalvermittlerPageContentText5: "HRM Raiffeisen Suisse, tél. 071 225 89 28",
    personalvermittlerButtonLabel: "Accès pour les recruteurs",
    personalvermittlerButtonUrl: "https://wd3.myworkday.com/wday/authgwy/raiffeisen/login.htmld?redirect=n",
    jobaboPageTitle: "Créer un abonnement de recherche",
    jobaboPageIntroText: "Voulez-vous être régulièrement informé des nouvelles offres d'emploi par la Raiffeisen? Ouvrez votre profil de recherche d'emploi individuel et vous serez automatiquement informé des offres d'emploi appropriées.",
    jobaboEmailLabel: "E-Mail",
    emailFieldValidationText: "Merci de saisir une adresse e-mail valide",
    jobaboNameLabel: "JobAbo Désignation",
    nameFieldValidationText: "Merci de compléter ce champ obligatoire.",
    jobaboSubscriptionFormTitle: "JobAbo",
    jobaboSuccessText1: "Le formulaire a été envoyé avec succès ! Vous recevrez sous peu un e-mail de confirmation.",
    jobaboSuccessText2: "Merci de confirmer votre inscription à l’abonnement de recherche d’emploi.",
    displayAsGrid: "Als Gitter anzeigen",
    jobGridColumns: "Anzahl Spalten",
    error: "Malheureusement, une erreur est survenue lors du chargement de la page. Veuillez réessayer plus tard.",
    loadmorejobs: "Afficher plus",
    headerLogoTitle: "Raiffeisen Suisse",
    headerLogoLink1Url: "https://www.raiffeisen.ch/rch/fr.html",
    headerNavLink1Label: "Portail Emploi",
    headerNavLink1Url: "https://jobs.raiffeisen.ch//?lang=fr",
    headerNavLink2Label: "Recruteur",
    headerNavLink2Url: "/personalvermittler/?lang=fr",
    headerNavLink3Label: "JobAbo",
    headerNavLink3Url: "/jobabo/?lang=fr",
    footerTopLink1Label: "Carrière",
    footerTopLink1Url: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/carriere.html",
    footerTopLink2Label: "Travailler chez Raiffeisen Suisse",
    footerTopLink2Url: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/carriere/travailler-chez-raiffeisen-suisse.html",
    footerTopLink3Label: "Travailler dans une Banque Raiffeisen",
    footerTopLink3Url: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/votre-banque-locale.html",
    footerTopLink4Label: "Formation chez Raiffeisen",
    footerTopLink4Url: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/carriere/formation-chez-raiffeisen.html",
    footerNavLink1Label: "Conditions d’utilisation",
    footerNavLink1Url: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/groupe-raiffeisen/disclaimer-website-f/conditions-d-utilisation-de-nos-sites-web.html",
    footerNavLink2Label: "Mentions légales et disclaimer",
    footerNavLink2Url: "https://www.raiffeisen.ch/rch/fr/qui-sommes-nous/groupe-raiffeisen/disclaimer-website-f/conditions-d-utilisation-de-nos-sites-web.html",
    footerNavLink3Label: "Paramètres des cookies",
    footerCopyright: "© Raiffeisen Suisse",
    footerSocialFacebookUrl: "https://www.facebook.com/RaiffeisenMemberPlus.FR/",
    footerSocialTwitterUrl: "https://www.twitter.com/raiffeisen_ch",
    footerSocialLinkedinUrl: "https://www.linkedin.com/company/raiffeisen-switzerland",
    footerSocialInstagramUrl: "https://www.instagram.com/raiffeisen_youngmember_f/",
  },


  it: {
    appTitle: "Borsa lavoro Raiffeisen",
    appTitleSeparator: "–",
    appTitleAddition: "Con noi per nuovi orizzonti",
    jobboerseIntroText1: "Nella sezione",
    jobboerseIntroLinkLabel: "Processo di candidatura e consigli",
    jobboerseIntroLinkUrl: "https://www.raiffeisen.ch/rch/it/chi-siamo/carriera-impieghi/processo-di-candidatura-e-contatto/processo-di-candidatura-e-consigli.html",
    jobboerseIntroText2: ", scopra quali passi la attendono dopo aver inoltrato la sua candidatura online e come è strutturato il suo percorso verso Raiffeisen.",
    searchPlaceholder: "inserire termine di ricerca",
    searchPlaceholderJobabo: "Termine di ricerca (opzionale)",
    filtersTitle: "Ricerca di un posto di lavoro",
    showFilters: "Filtro",
    hideFilters: "Nascondere filtro",
    resetFilters: "Ripristinare filtro",
    submitFilters: "Acquisire",
    inputSelectPlaceholder: "Tutti",
    noJobs: "Non ci sono annunci di lavoro.",
    jobaboModalButtonLabel: "Registrare abbonamento di ricerca lavoro",
    filterBeschäftigungsartLabel: "Sono interessato a",
    filterFachbereichLabel: "Settore specialistico",
    filterPensumLabel: "Grado di occupazione",
    filterRegionLabel: "Regione",
    filterFührungsfunktionLabel: "con responsabilità dirigenziale",
    personalvermittlerPageTitle: "Agente",
    personalvermittlerPageIntroText: "Informazione per il collocamento del personale",
    personalvermittlerPageContentText1: "Stimati agenti di collocamento del personale,",
    personalvermittlerPageContentText2: "cooperiamo con agenzie di collocamento del personale selezionate che hanno sottoscritto le nostre disposizioni contrattuali per collocamenti del personale con onorario in base al risultato. Al momento non accettiamo altre agenzie di collocamento del personale.",
    personalvermittlerPageContentText3: "Confidiamo nella vostra comprensione.",
    personalvermittlerPageContentText4: "Cordiali saluti",
    personalvermittlerPageContentText5: "HRM Raiffeisen Svizzera, tel. 071 225 89 28",
    personalvermittlerButtonLabel: "Accesso per i selezionatori",
    personalvermittlerButtonUrl: "https://wd3.myworkday.com/wday/authgwy/raiffeisen/login.htmld?redirect=n",
    jobaboPageTitle: "Registrare abbonamento di ricerca",
    jobaboPageIntroText: "Desidera ricevere aggiornamenti costanti sulle nuove offerte di lavoro di Raiffeisen? Crei il Suo profilo di ricerca personalizzato e sarà automaticamente messo al corrente delle offerte di lavoro più adatte a Lei.",
    jobaboEmailLabel: "E-Mail",
    emailFieldValidationText: "Inserisca un indirizzo e-mail valido",
    jobaboNameLabel: "JobAbo Denominazione",
    nameFieldValidationText: "Compili questo campo obbligatorio",
    jobaboSubscriptionFormTitle: "JobAbo",
    jobaboSuccessText1: "Il formulario è stato inviato con successo! Riceverà un'e-mail di conferma a breve.",
    jobaboSuccessText2: "Confermi la sua iscrizione all'abbonamento di ricerca lavoro.",
    displayAsGrid: "Als Gitter anzeigen",
    jobGridColumns: "Anzahl Spalten",
    error: "Purtroppo si è verificato un errore durante il caricamento della pagina. Si prega di riprovare più tardi.",
    loadmorejobs: "Visualizzare di più",
    headerLogoTitle: "Raiffeisen Svizzera",
    headerLogoLink1Url: "https://www.raiffeisen.ch/rch/it.html",
    headerNavLink1Label: "Borsa lavoro",
    headerNavLink1Url: "https://jobs.raiffeisen.ch/?lang=it",
    headerNavLink2Label: "Agente",
    headerNavLink2Url: "/personalvermittler/?lang=it",
    headerNavLink3Label: "JobAbo",
    headerNavLink3Url: "/jobabo/?lang=it",
    footerTopLink1Label: "Carriera",
    footerTopLink1Url: "https://www.raiffeisen.ch/rch/it/chi-siamo/carriera.html",
    footerTopLink2Label: "Lavorare presso Raiffeisen Svizzera",
    footerTopLink2Url: "https://www.raiffeisen.ch/rch/it/chi-siamo/carriera/lavorare-presso-raiffeisen-svizzera.html",
    footerTopLink3Label: "Lavorare presso una Banca Raiffeisen",
    footerTopLink3Url: "https://www.raiffeisen.ch/rch/it/chi-siamo/la-sua-banca-locale.html",
    footerTopLink4Label: "Formazione presso Raiffeisen",
    footerTopLink4Url: "https://www.raiffeisen.ch/rch/it/chi-siamo/carriera/formazione-raiffeisen.html",
    footerNavLink1Label: "Condizioni di utilizzo",
    footerNavLink1Url: "https://www.raiffeisen.ch/rch/it/chi-siamo/gruppo-raiffeisen/disclaimer-website-i/condizioni-di-utilizzo-per-i-nostri-siti-web.html",
    footerNavLink2Label: "Impressum e disclaimer",
    footerNavLink2Url: "https://www.raiffeisen.ch/rch/it/chi-siamo/gruppo-raiffeisen/disclaimer-website-i/condizioni-di-utilizzo-per-i-nostri-siti-web.html",
    footerNavLink3Label: "Impostazioni dei cookie",
    footerCopyright: "© Raiffeisen Svizzera",
    footerSocialFacebookUrl: "https://www.facebook.com/RaiffeisenMemberPlus.Svizzera/",
    footerSocialTwitterUrl: "https://www.twitter.com/raiffeisen_ch",
    footerSocialLinkedinUrl: "https://www.linkedin.com/company/raiffeisen-switzerland",
    footerSocialInstagramUrl: "https://www.instagram.com/raiffeisen_youngmember_i/",
  },

};
