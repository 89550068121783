import { Button, Checkbox } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { i18n } from "../util/i18n";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

// Local storage context for favourites etc.
import { useLocalStorage } from "../util/LocalStorageContext";

/**
 * A Favourite Button component, for adding/removing a Job object to the Favourites in LocalStorage.
 * The localStorage key is configered in `settings.localStorageKeyFavourites`
 * and provided via global `LocalStorageContext` and `useLocalStorageHook`.
 * Each job will be added under the key `job.id`.
 *
 * - You can configure the icons
 * - CheckBox has a className = `job-favourite-checkbox`
 * - Remaining props are forwarded to the MUI Checkbox component.
 * - See https://mui.com/material-ui/react-checkbox/#icon
 *
 */
const Favourite = ({ job, loading, uncheckedIcon, checkedIcon, ...props }) => {
  const jobId = job?.id;
  const [stored, setStored] = useLocalStorage();

  const iconUnchecked = uncheckedIcon || <FavoriteBorderIcon />;
  const iconChecked = checkedIcon || <FavoriteIcon />;

  const handleFavouriteToggle = (event) => {
    let newState = { ...stored }; // copy the current state
    // toggle: if the jobId is already existing, remove it. Otherwise, add it.
    if (stored[jobId]) {
      delete newState[jobId];
    } else {
      newState[jobId] = job;
    }

    setStored(newState);
  };

  return (
    <>
      {jobId && (
        <Checkbox
          className="job-favourite-checkbox"
          value={true}
          disabled={loading}
          icon={iconUnchecked}
          checkedIcon={iconChecked}
          checked={stored[jobId] ? true : false}
          onChange={handleFavouriteToggle}
          {...props}
        />
      )}
      {!jobId && <div>No JobId</div>}
    </>
  );
};

export default Favourite;
