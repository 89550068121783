import React, { useState, useEffect } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Fade,
  Grid,
  IconButton,
  Input,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useSettings } from "../util/SettingsContext";
import Favourite from "../components/Favourite";
import Grow from "@mui/material/Grow";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { useCopyToClipboard } from 'usehooks-ts'

/**
 * This renders a single job for this project as a "list" item.
 * You may want to use `JobListItem` with children, the "Favourite" component etc.
 *
 * This component will be rendered into a MUI `List`
 * See: https://mui.com/material-ui/react-list/
 * API: https://mui.com/material-ui/api/list/
 *
 * TODO: For Transitions see MUI docs,
 * especially: https://mui.com/material-ui/transitions/#child-requirement
 *
 * @param {*} job
 */
const JobListItem = ({ job, removeFavouriteIcon }) => {
  const { settings } = useSettings();

  const [jobLink, copy] = useCopyToClipboard();

  const getPensum = (job) => {
    let custompensum = job?.szas["sza_pensum"] || "";
    return `${custompensum}`;
  };

  const unescapeHtml = (text) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.documentElement.textContent;
  };

  return (
    <ListItem key={`${job.id}-list-item`} className="job-list-item" id={`${job.id}-list-item`}>
      <Fade in={true} timeout={500}>
        <ListItemButton component="a" href={job.links.directlink} target="_blank">
          <ListItemText
            className="jobTitle"
            primary={`${job.title} ${getPensum(job)}`}
            secondary={unescapeHtml(job.attributes[settings.jobAttributes.Arbeitsort])}
          />
        </ListItemButton>
      </Fade>
      {settings.enableCopyToClipboard && (
        <ListItemSecondaryAction>
        <button className="to-clipboard" onClick={() => copy(job.links.directlink)}>Copy Link</button>
      </ListItemSecondaryAction>
      )}    
      {settings.enableFavourites && (
        <ListItemSecondaryAction>
        <Favourite job={job} checkedIcon={removeFavouriteIcon} />
      </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const JobGridItem = ({ job }) => {
  const { settings } = useSettings();
  return (
    <Grid      
      item
      xs={12} sm={12} md={12 / (settings.jobGridColumns - 1)} lg={12 / settings.jobGridColumns}
      key={`${job.id}-grid-item`}    
    >
      <Fade in={true} timeout={500}>
        <Card className="job-grid-item" elevation={0} sx={{ width: "100%", height: "100%" }}>
          <ListItemButton
            component="a"
            href={job.links.directlink}
            sx={{ height: "100%" }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Typography variant="h5" component="div">
                <ListItemText
                  primary={job.title}
                  secondary={job.attributes[settings.jobAttributes.Arbeitsort]}
                />
              </Typography>
            </CardContent>
          </ListItemButton>
          {settings.enableCopyToClipboard && (
              <button className="to-clipboard" onClick={() => copy(job.links.directlink)}>Copy Link</button>
          )} 
          {settings.enableFavourites && (
            <Favourite job={job} />
          )}
        </Card>
      </Fade>
    </Grid>
  );
};

export { JobListItem, JobGridItem };

// /**
//  * You can do anything here, just make sure to include a unqiue key
//  */
// const dummyItem = ({ job }) => {
//   return <div key={`job-list-item-${job.id}`}>{job.title}</div>;
// };
// export { dummyItem as JobItem };
