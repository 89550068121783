import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";
import Grid from "@mui/material/Unstable_Grid2";
import TextSearchFilterInput from "../components/TextSearchFilterInput";
import AttributeFilterDropdown from "../components/AttributeFilterDropdown";
import { useFilters } from "../util/FiltersContext";
import AttributeFilterCheckbox from "../components/AttributeFilterCheckbox";
import JobAboSubscriptionForm from "../components/JobAboSubscriptionForm";

const JobAboForm = ({ attributes, filtersParams, setFiltersParams }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate the email field
    const isEmailValid = validateEmail(email);
    setEmailError(!isEmailValid);

    // Validate the jobabo_bezeichnung field
    const isJobaboBezeichnungValid = jobaboBezeichnung.trim() !== '';
    setJobaboBezeichnungError(!isJobaboBezeichnungValid);

    // Check if both fields are valid before performing the form submission
    if (isEmailValid && isJobaboBezeichnungValid) {
      // Perform the form submission here
    }
  };

  const { settings } = useSettings();
  const FILTER = settings.filterIds;
  const { data: filtersData } = useFilters();

  // Email field validation
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(false); // Clear the error whenever the user starts typing
  };

  const handleEmailBlur = () => {
    setEmailError(!validateEmail(email)); // Check if the email is valid on blur
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isEmailValid = validateEmail(email);

  // jobabo_bezeichnung field validation
  const [jobaboBezeichnung, setJobaboBezeichnung] = useState('');
  const [jobaboBezeichnungError, setJobaboBezeichnungError] = useState(false);

  const isJobaboBezeichnungValid = jobaboBezeichnung.trim() !== '';

  const handleJobaboBezeichnungChange = (event) => {
    const value = event.target.value;
    setJobaboBezeichnung(value);
    setJobaboBezeichnungError(false); // Clear the error whenever the user starts typing
  };

  const handleJobaboBezeichnungBlur = () => {
    setJobaboBezeichnungError(jobaboBezeichnung.trim() === ''); // Check if the value is filled on blur
  };

  return (
      <JobAboSubscriptionForm
          attributes={attributes}
          filtersParams={filtersParams}
          setFiltersParams={setFiltersParams}
          className="jobabo-subscription-form"
      >
        <Grid container direction="column" className="jobabo-first-column">
          <Grid
              container
              direction="column"
              className="jobabo-filter-container"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
          >
            <Grid item>
              <AttributeFilterDropdown
                  label={i18n.translations.filterBeschäftigungsartLabel}
                  filterAttributeId={FILTER.Beschäftigungsart}
                  attributes={filtersData.attributes}
                  filtersParams={filtersParams}
                  setFiltersParams={setFiltersParams}
              ></AttributeFilterDropdown>
            </Grid>
            <Grid item>
              <AttributeFilterDropdown
                  label={i18n.translations.filterFachbereichLabel}
                  filterAttributeId={FILTER.Fachbereich}
                  attributes={filtersData.attributes}
                  filtersParams={filtersParams}
                  setFiltersParams={setFiltersParams}
              ></AttributeFilterDropdown>
            </Grid>
            <Grid item>
              <AttributeFilterDropdown
                  label={i18n.translations.filterPensumLabel}
                  filterAttributeId={FILTER.Pensum}
                  attributes={filtersData.attributes}
                  filtersParams={filtersParams}
                  setFiltersParams={setFiltersParams}
              ></AttributeFilterDropdown>
            </Grid>
            <Grid item>
              <AttributeFilterDropdown
                  label={i18n.translations.filterRegionLabel}
                  filterAttributeId={FILTER.Region}
                  attributes={filtersData.attributes}
                  filtersParams={filtersParams}
                  setFiltersParams={setFiltersParams}
              ></AttributeFilterDropdown>
            </Grid>
            <Grid item>
              <AttributeFilterCheckbox
                  label={i18n.translations.filterFührungsfunktionLabel}
                  filterAttributeId={FILTER.Führungsfunktion}
                  attributes={filtersData.attributes}
                  filtersParams={filtersParams}
                  setFiltersParams={setFiltersParams}
              ></AttributeFilterCheckbox>
            </Grid>
          </Grid>
          <Grid
              container
              direction="column"
              className="jobabo-second-column"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
          >
            <Grid item>
              <TextSearchFilterInput
                  filtersParams={filtersParams}
                  setFiltersParams={setFiltersParams}
                  label={i18n.translations.searchPlaceholderJobabo}
              ></TextSearchFilterInput>
            </Grid>
            <Grid item>
              <TextField
                  id="jobabo_email"
                  name="jobabo_email"
                  label={i18n.translations.jobaboEmailLabel}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  required
                  error={emailError}
                  helperText={emailError ? i18n.translations.emailFieldValidationText : ''}
              />
            </Grid>
            <Grid item>
              <TextField
                  id="jobabo_bezeichnung"
                  name="jobabo_bezeichnung"
                  label={i18n.translations.jobaboNameLabel}
                  value={jobaboBezeichnung}
                  onChange={handleJobaboBezeichnungChange}
                  onBlur={handleJobaboBezeichnungBlur}
                  required
                  error={jobaboBezeichnungError}
                  helperText={jobaboBezeichnungError ? i18n.translations.nameFieldValidationText : ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Button
              className="jobabo-submit-button primaryButton"
              variant="contained"
              type="submit"
              disabled={!isEmailValid || !isJobaboBezeichnungValid}
          >
            {i18n.translations.jobaboModalButtonLabel}
          </Button>
        </Grid>
      </JobAboSubscriptionForm>
  );
};

export default JobAboForm;