import React, { useEffect, useState } from "react";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const defaultIcon = (
    <svg width="20" height="21" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="1.50098" width="17" height="17" rx="3.5" stroke="#1A1A1A"/>
    </svg>
);

const hoverIconUnchecked = (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2.00299" width="16" height="16" rx="3" stroke="#1A1A1A" stroke-width="2"/>
    </svg>
);

const checkedIcon = (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2.00299" width="16" height="16" rx="3" fill="#1A1A1A" stroke="#1A1A1A" stroke-width="2"/>
        <path d="M5 10.003L8 13.003L15 6.00299" stroke="white" stroke-width="2"/>
    </svg>

);

const hoverIconChecked = (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2.00293" width="16" height="16" rx="3" fill="#4D4D4D" stroke="#4D4D4D" stroke-width="2"/>
        <path d="M5 10.0029L8 13.0029L15 6.00293" stroke="white" stroke-width="2"/>
    </svg>

);


const AttributeFilterCheckbox = ({
                                     filtersParams,
                                     setFiltersParams,
                                     attributes,
                                     filterAttributeId,
                                     label = "Filter",
                                     ...props
                                 }) => {
    if (!attributes) {
        return <div>Project Attributes are required</div>;
    }
    if (!filterAttributeId) {
        return <div>Filter attribute ID is required</div>;
    }

    // Assuming the attribute type is stored in a property called "type"
    // If the type is not "BOOLEAN", return null to skip rendering the component
    const filterAttribute = attributes.find(
        (attribute) =>
            attribute.id === filterAttributeId && attribute.type === "BOOLEAN"
    );

    if (!filterAttribute) {
        return null;
    }

    // State to keep track of the checkbox value
    const [checkboxValue, setCheckboxValue] = useState(
        filtersParams.f?.[filterAttributeId] === "1"
    );

    useEffect(() => {
        // Update checkboxValue whenever filtersParams change
        setCheckboxValue(filtersParams.f?.[filterAttributeId] === "1");
    }, [filtersParams, filterAttributeId]);

    // Function to handle changes when checkbox is clicked
    const handleChanged = (event) => {
        const value = event.target.checked ? "1" : "0";

        // Update filtersParams with the selected boolean value (1 or null)
        setFiltersParams((prevParams) => ({
            ...prevParams,
            f: {
                ...prevParams.f,
                [filterAttributeId]: value,
            },
        }));

        // Update the local state of the checkbox
        setCheckboxValue(event.target.checked);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <>
            <FormGroup onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={isHovered ? hoverIconUnchecked : defaultIcon}
                            checkedIcon={isHovered ? hoverIconChecked : checkedIcon}
                            id={`attribute-filter-group-${filterAttributeId}`}
                            checked={checkboxValue}
                            onChange={handleChanged}
                            disableRipple
                        />
                    }
                    label={label}
                />
            </FormGroup>
        </>
    );
};

export default AttributeFilterCheckbox;