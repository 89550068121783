import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#e82025",
        },
        secondary: {
            main: "#0000FF",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 575,
            md: 991,
            lg: 1118,
            xl: 1442,
        },
    },
    typography: {
        fontFamily: [
            '"FrutigerNext"',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiListItemButton: {
            defaultProps: {
                disableTouchRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                disableTouchRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableTouchRipple: true,
                sx: {
                    ":hover": {
                        backgroundColor: "transparent"
                    }
                }
            },
        },
    },
});

import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";
import { useFiltersParams } from "../util/FiltersParamsContext";
import { useFetchUrl } from "../util/UrlParams";

import { useFilters } from "../util/FiltersContext";

/**
 * Import your components here
 */
import { Button, Switch, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForever from "@mui/icons-material/DeleteForever";
import JobListPagination from "../components/JobListPagination";

import TextSearchFilterInput from "../components/TextSearchFilterInput";
import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import WorkloadFilterSlider from "../components/WorkloadFilterSlider";
import ResetFiltersButton from "../components/ResetFiltersButton";
import JobAboSubscriptionModal from "../components/JobAboSubscriptionModal";
import JobAboSubscriptionForm from "../components/JobAboSubscriptionForm";
import JobAboForm from "./form";

/**
 * Main Layout for JobAbo
 */
const JobAboLayout = ({}) => {
  const { settings, setSettings } = useSettings();
  const { data: filtersData } = useFilters();

  // This is what we pass to child components to update the filtersParams
  const { filtersParams, setFiltersParams } = useFiltersParams();

  /**
   * Return Jobabo main page
   */
  return (
      <ThemeProvider theme={theme}>
          {/* 2nd Row */}
          <Grid container component="main">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={10} className="pageTitle">
                  <Typography variant="h1">
                      {i18n.translations.jobaboPageTitle}
                  </Typography>
              </Grid>
              <Grid container>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={8} className="introContainer">
                      <Typography className="introduction">
                          {i18n.translations.jobaboPageIntroText}
                      </Typography>
                  </Grid>
              </Grid>

              <Grid container className="redLineContainerTop">
                  <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className="introRedLine"></Grid>
                  <Grid item xs={6} sm={4} md={4} lg={4} xl={4}></Grid>
                  <Grid item xs={0} sm={4} md={4} lg={4} xl={4} sx={{ display: { xs: 'none'} }}></Grid>
              </Grid>

              {/* Jobabo Section */}
              <Grid
                  className="jobabo-container"
                  container
                  direction="row"
                  justifyContent="space-between"
              >

                    <JobAboForm
                          attributes={filtersData.attributes}
                          filtersParams={filtersParams}
                          setFiltersParams={setFiltersParams}
                    />

              </Grid>
          </Grid>
      </ThemeProvider>
  );
};

export default JobAboLayout;
