import { Autocomplete, Button, Checkbox, TextField } from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";

import React from "react";
import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ConnectedTvOutlined } from "@mui/icons-material";
import { useFilters } from "../util/FiltersContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const convertToOptionsList = (attributes, attributeId) => {
//   console.info(
//     "Converting attributes to options list",
//     attributeId,
//     attributes
//   );
//   let options = [];
//   let topLevelOptions = attributes
//     .filter((item) => item.id == attributeId)
//     .map((attribute) => attribute.values)[0];

//   console.log("Top Level Options", topLevelOptions);

//   Object.entries(topLevelOptions).map((item) => {
//     let newOption = {
//       id: item[0],
//       label: item[1],
//       value: item[0], // unless it's a group, then it's the concatenation of all child ids
//     };
//     // find attributes that have this attribute as a parent_view_id
//     let children = attributes.filter((attr) => attr.parent_value_id == item[0]);

//     if (children.length > 0) {
//       newOption.group = true;
//       newOption.value = children
//         .map((child) => {
//           console.log("Child", child);
//           return Object.entries(child.values).map((item) => {
//             console.log("Child Item", item[0]);
//             return item[0];
//           });
//         })
//         .join(",");
//     } else options.push(newOption);
//   });

//   console.log("Options", options);
//   return options;
// };

// /**
//  * Finds the Options within the options list that match the current filter value.
//  * The selectedIdValues are a string of comma-separated ids.
//  *
//  * @param {*} allOptions
//  * @param {*} selectedIdValues
//  */
// const getSelectedOptions = (allOptions, selectedIdValues) => {
//   if (!selectedIdValues) {
//     return [];
//   }
//   let selectedOptions = [];

//   selectedIdValues.split(",").map((id) => {
//     console.log("Looking for id", id);
//     allOptions
//       .filter((option) => option.id == id)
//       .map((option) => {
//         console.log("Found option", option);
//         if (option.group) {
//           // find and add all children
//           console.log("Found group", option);
//           //selectedOptions.push(option);
//           selectedOptions.push(
//             ...allOptions.filter((child) => child.parent == option.id)
//           );
//         } else {
//           // Just add the single option item
//           selectedOptions.push(option);
//         }
//       });
//   });

//   // Magic one-liner to remove duplicates by id
//   selectedOptions = selectedOptions.filter(
//     (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
//   );

//   console.log("selected", selectedOptions);
//   return selectedOptions;
// };

/**
 * MUI Autocomplete API:
 *
 * - https://mui.com/material-ui/api/autocomplete/
 * - https://mui.com/material-ui/api/autocomplete/#props
 * TreeView API:
 * - https://mui.com/components/tree-view/
 */

const AttributeFilterTreeViewer = ({
                                     filtersParams,
                                     setFilterParams,
                                     filterAttributeId,
                                     label = "Filter",
                                     placeholder,
                                     ...props
                                   }) => {
  const { settings, setSettings } = useSettings();
  const filters = useFilters();
  // reference the autocomplete component
  const autocompleteRef = React.useRef(null);

  // State for the currently selected options
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  console.log(filters);

  const attributes = filters?.data?.attributes;
  if (!attributes) {
    return <div>Project Filter attributes are required</div>;
  }

  // //const filterName = "Klinik / Institut";
  const multiple = true;

  // // Convert the attributes for this filter into a flat list of options
  const options = [{ label: "Klinik / Institut" }, { label: "Test" }];

  // getAutocompleteOptions (filters, filterId);
  // let selectedOptions = getSelectedOptions(
  //   options,
  //   filtersParams[`f:${filterId}`]
  // );

  const handleChanged = (event, value) => {
    console.log("handleChanged", event);
    // // keep all filter values, but change ONLY the one that this component is responsible for.
    // // Reset offset to zero.
    // let newFilterParams = {
    //   ...filtersParams,
    //   [`f:${filterId}`]: Array.isArray(value)
    //     ? value.map((v) => v.id).join(",")
    //     : value,
    //   offset: 0,
  };

  /**
   * @returns TextField of the Autocomplete component.
   * see: https://mui.com/material-ui/api/autocomplete/#props
   */
  const renderInput = (params) => {
    return (
        <>
          <TextField
              {...params}
              label={""}
              placeholder={placeholder || label}
              variant="standard"
          />
        </>
    );
  };

  return (
      <>
        <Autocomplete
            ref={autocompleteRef}
            id={`attribute-filter-${filterAttributeId}`}
            key={`attribute-filter-${filterAttributeId}`}
            multiple={multiple}
            value={selectedOptions}
            options={options}
            renderInput={renderInput}
            disableCloseOnSelect
        ></Autocomplete>
      </>
  );
};

//   console.log("Setting new filter params", value, newFilterParams);

//   setFilterParams(newFilterParams);
// };

// return (
//   <>
//     <Autocomplete
//       ref={autocompleteRef}
//       id={`attribute-filter-${filterId}`}
//       multiple={multiple}
//       value={selectedOptions}
//       onChange={handleChanged}
//       options={options}
//       disableCloseOnSelect
//       getOptionLabel={(option) => option?.label}
//       isOptionEqualToValue={(option, value) => {
//         // to tell the component which options are selected, need to match ids of filter option with current state
//         return option.id == value.id;
//       }}
//       renderOption={(props, option, { selected }) => (
//         <li {...props}>
//           <Checkbox
//             icon={icon}
//             checkedIcon={checkedIcon}
//             style={
//               option.parent
//                 ? { marginLeft: 12, marginRight: 8 }
//                 : { marginRight: 8 }
//             }
//             value={option.id}
//             checked={selected}
//           />
//           {option.label}
//         </li>
//       )}
//       style={{ width: "400px" }}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={label}
//           placeholder={placeholder || label}
//         />
//       )}
//     />
//   </>
// );

export default AttributeFilterTreeViewer;