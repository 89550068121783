import React from "react";
import PersonalvermittlerLayout from "./personalvermittler/layout";

const PersonalvermittlerPage = () => {

  return (
    <>
      <PersonalvermittlerLayout />
    </>
  );
};

export default PersonalvermittlerPage;
