import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

import { useFiltersParams } from "../util/FiltersParamsContext";
import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";
import { useFetchUrl } from "../util/UrlParams";

import { useFilters } from "../util/FiltersContext";
import { useLoadJobs } from "../util/QueryService";

import { useDebounce } from "ahooks";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e82025",
    },
    secondary: {
      main: "#0000FF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 575,
      md: 991,
      lg: 1118,
      xl: 1442,
    },
  },
  typography: {
    fontFamily: [
      '"FrutigerNext"',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableTouchRipple: true,
        sx: {
          ":hover": {
            backgroundColor: "transparent"
          }
        }
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableTouchRipple: true,
        sx: {
          ":hover": {
            backgroundColor: "transparent"
          }
        }
      },
    },
  },
});

/**
 * Import your components here
 */
import { Button, Chip, Typography, Checkbox, Collapse, FormControlLabel, FormGroup, TextField } from "@mui/material";
import JobListPagination from "../components/JobListPagination";
import JobListLoadMore from "../components/JobListLoadMore";

import AttributeFilterDropdown from "../components/AttributeFilterDropdown";
import AttributeFilterCheckbox from "../components/AttributeFilterCheckbox";

import ResetFiltersButton from "../components/ResetFiltersButton";
import TextSearchFilterInput from "../components/TextSearchFilterInput";

import Header from "../components/Header";
import Footer from "../components/Footer";

// Items are typically project specific
import JobList from "../components/JobList";
import { JobGridItem, JobListItem } from "./jobItem";

/**
 * Main Layout for CC
 */
const Layout = ({}) => {
  const { settings, setSettings } = useSettings();
  const { data: filtersData } = useFilters();

  // This is what we pass to child components to update the filtersParams
  const { filtersParams, setFiltersParams } = useFiltersParams();
  // useDebounce updates the debouncedParams state variable,
  // 200ms after filtersParams has been changed (avoid flickering)
  const debouncedParams = useDebounce(filtersParams, {
    wait: 100,
  });

  // internal state will get updated with a delay after an update.
  // Changes to this will trigger a reload of the jobs list
  const [internalParams, setInternalParams] = useState(filtersParams);

  // Flag, if a "submit" has been triggered. If settings.autoSubmit = false,
  // changes to filtersParams only get applied (and trigger a reload of new job list)
  // if this has been set to true. Flag gets cleared after every submit
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  // Dependend filter for Fachbereich, Beschäftigungsart
  const [selectedFachbereich, setSelectedFachbereich] = useState(null);
  const [selectedBeschäftigungsart, setSelectedBeschäftigungsart] = useState(null);


  // create the fetch url from the current internalParams and load the job list from the backend
  const url = useFetchUrl(internalParams);
  const { data: jobsData } = useLoadJobs(url);

  useEffect(() => {
    // if autoSubmit, we apply the changed immediatly
    if (settings.autoSubmit) {
      setInternalParams(filtersParams);
    } else {
      // if NOT autoSubmit, we apply the changes only once triggerSubmit flag has been set
      if (triggerSubmit) {
        setInternalParams(filtersParams);
      }
    }
    // in any case, clear the submit trigger flag
    setTriggerSubmit(false);
  }, [debouncedParams, triggerSubmit]);

  // shortcut to reference filterIds from settings
  const FILTER = settings.filterIds;

  // Checkbox to display filters on mobile
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [isShowFiltersChecked, setIsShowFiltersChecked] = useState(matches);

  useEffect(() => {
    const handleResize = () => {
      if (matches) {
        setIsShowFiltersChecked(true);
      } else {
        setIsShowFiltersChecked(false);
      }
    };

    handleResize();

    // Add event listener for window resize
    //window.addEventListener('resize', handleResize);
    //window.addEventListener('orientationchange', handleResize);

    // Clean up the event listener on component unmount
    //return () => {
    //  window.removeEventListener('resize', handleResize);
    //  window.removeEventListener('orientationchange', handleResize);
    //};
  }, [matches]); // useEffect will re-run whenever the value of `matches` changes

  const handleCheckboxChange = (event) => {
    setIsShowFiltersChecked(event.target.checked);
  };

  /**
   * Return or Career center main page
   */
  return (
    <ThemeProvider theme={theme}>
      {/* 2nd Row */}
      <Grid container component="main">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10} className="pageTitle">
          <Typography variant="h1">
            {i18n.translations.appTitle}&nbsp;{i18n.translations.appTitleSeparator} <br/>{i18n.translations.appTitleAddition}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={8} className="introContainer">
            <Typography className="introduction">
              {i18n.translations.jobboerseIntroText1} <a target="_blank" rel="noopener" href={i18n.translations.jobboerseIntroLinkUrl}>{i18n.translations.jobboerseIntroLinkLabel}</a>{i18n.translations.jobboerseIntroText2}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="redLineContainerTop">
          <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className="introRedLine"></Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={4}></Grid>
          <Grid item xs={0} sm={4} md={4} lg={4} xl={4} sx={{ display: { xs: 'none'} }}></Grid>
        </Grid>

        {/* Careercenter Section */}
        <Grid
          className="career-center"
          container
          direction="row"
          justifyContent="space-between"
        >
          {/* Filter Section */}

          <Grid
            container
            direction="column"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            <FormGroup className="mobileFilters">
              <Typography className="filterTitle" sx={{ display: { xs: 'none', sm: 'none', md: 'block'}}}>
                {i18n.translations.filtersTitle}
              </Typography>

              <Grid item>
                <TextSearchFilterInput
                    alignIcon="end"
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                    label={i18n.translations.searchPlaceholder}
                ></TextSearchFilterInput>
              </Grid>

              <FormControlLabel
                  sx={{ display: { sm: 'flex', md: 'none'}, justifyContent: "space-between", margin: "0" }}
                  className="filterToggleContainer"
                  labelPlacement="start"
                  control={
                    <Checkbox
                        icon={<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 10.0283L10 18.0283H8V10.0283H0L0 8.02832H8V0.0283203L10 0.0283203V8.02832L18 8.02832V10.0283L10 10.0283Z" fill="#1A1A1A"/>
                              </svg>
                              }
                        checkedIcon={<svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.00500488" width="18" height="2" fill="#1A1A1A"/>
                                     </svg>
                                     }
                        checked={isShowFiltersChecked}
                        onChange={handleCheckboxChange}
                    />
                  }
                  label={i18n.translations.showFilters}
              />
              {isShowFiltersChecked ? (
                <Grid
                    className="filter-container"
                    container
                    direction="column"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >

                <Grid item>
                  <AttributeFilterDropdown
                    label={i18n.translations.filterBeschäftigungsartLabel}
                    filterAttributeId={FILTER.Beschäftigungsart}
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                  ></AttributeFilterDropdown>
                </Grid>

                <Grid item>
                  <AttributeFilterDropdown
                    label={i18n.translations.filterFachbereichLabel}
                    filterAttributeId={FILTER.Fachbereich}
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                  ></AttributeFilterDropdown>
                </Grid>

                <Grid item>
                  <AttributeFilterDropdown
                      label={i18n.translations.filterPensumLabel}
                      filterAttributeId={FILTER.Pensum}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                  ></AttributeFilterDropdown>
                </Grid>

                <Grid item>
                  <AttributeFilterDropdown
                      label={i18n.translations.filterRegionLabel}
                      filterAttributeId={FILTER.Region}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                  ></AttributeFilterDropdown>
                </Grid>

                <Grid item className="filterFuerhungsfunktion">
                  <AttributeFilterCheckbox
                      label={i18n.translations.filterFührungsfunktionLabel}
                      filterAttributeId={FILTER.Führungsfunktion}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                  ></AttributeFilterCheckbox>
                </Grid>

                {/* Buttons Section */}
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    className="filterButtons"
                    sx={{ alignItems: { sm: 'center', md: 'flex-start'} }}
                >
                  <Grid container direction="column"  sx={{ alignItems: { sm: 'center', md: 'flex-start'}, width: '100%' }}>
                        <Button
                            sx={{ display: { sm: 'inline-block', md: 'none !important'} }}
                            className="submit-filters-button primaryButton"
                            onClick={() => {
                              setTriggerSubmit(true);
                            }}
                        >
                          {" "}
                          {i18n.translations.submitFilters}
                        </Button>


                    <ResetFiltersButton
                        filtersParams={filtersParams}
                        setFiltersParams={(value) => {
                          setFiltersParams(value);
                          setSelectedBeschäftigungsart(null); // clear selected
                          setSelectedFachbereich(null); // clear selected
                          setTriggerSubmit(true);
                        }}
                        setTriggerSubmit={setTriggerSubmit}
                    />

                  </Grid>
                </Grid>
              </Grid>
              ) : (
                  ''
              )}
            </FormGroup>
          </Grid>


          {/* Jobs Result Section */}
          <Grid
              container
              className="jobResults"
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
          >
            {/* Jobs List Section */}
            <Grid item xs={12} sm={12}>
              <JobList
                jobsData={jobsData}
                jobsPageSize={settings.jobPageSize}
                displayAsGrid={settings.displayAsGrid}
                enableFavourites={settings.enableFavourites}
                renderJobItem={(job) => {
                  return settings.displayAsGrid ? (
                    <JobGridItem key={job.id} job={job} />
                  ) : (
                    <JobListItem key={job.id} job={job} />
                  );
                }}
                notFoundComponent={<div>{i18n.translations.noJobs}</div>}
              ></JobList>
            </Grid>

            {/* Pagination Section */}
            <Grid item xs={12} sm={12}>
              {!settings.showLoadMore && (
                  <JobListPagination
                      jobsPageOptions={[4, 12, 24, 48, 96]}
                      filtersParams={filtersParams}
                      setFiltersParams={(value) => {
                        setFiltersParams(value);
                        setTriggerSubmit(true);
                      }}
                      totalJobs={jobsData?.total}
                  />
              )}

              {settings.showLoadMore && (
                  <JobListLoadMore
                      filtersParams={filtersParams}
                      setFiltersParams={(value) => {
                        setFiltersParams(value);
                        setTriggerSubmit(true);
                      }}
                      totalJobs={jobsData?.total}
                      ß
                  />
              )}
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Layout;
