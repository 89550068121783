import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

const LanguageSwitcher = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const currentLanguage = queryParams.get('lang');

    const handleLanguageChange = (language) => {
        const currentSearchParams = new URLSearchParams(window.location.search);
        currentSearchParams.set('lang', language);

        const newSearchParams = currentSearchParams.toString();
        window.location.href = `${window.location.pathname}?${newSearchParams}${window.location.hash}`;
    };



    return (
        <div>
            <IconButton
                className={currentLanguage === 'de' || !currentLanguage ? 'langIsActive' : ''}
                onClick={() => handleLanguageChange('de')}
                component="a"
                disableRipple
            >
                DE
            </IconButton>
            <IconButton
                className={currentLanguage === 'fr' ? 'langIsActive' : ''}
                onClick={() => handleLanguageChange('fr')}
                component="a"
                disableRipple
            >
                FR
            </IconButton>
            <IconButton
                className={currentLanguage === 'it' ? 'langIsActive' : ''}
                onClick={() => handleLanguageChange('it')}
                component="a"
                disableRipple
            >
                IT
            </IconButton>
        </div>
    );
};

export default LanguageSwitcher;