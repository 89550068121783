import React from "react";
import { useState, useEffect } from "react";

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import Settings from "../settings";

import { i18n } from "../util/i18n";
import JobAboSubmitButton from "./JobAboSubmitButton";
import FilterSection from "./FilterSection";

/**
 * Renders a JobAbo Subscriptionm Form
 * @returns JSX
 */
const JobAboSubscriptionForm = ({
                                  filtersParams,
                                  setFilterParams,
                                  attributes = null,
                                  loading = false,
                                  ...props
                                } = props) => {
  const [responseHtml, setResponseHtml] = useState("");

  const [aboFilters, setAboFilters] = React.useState(filtersParams);
  console.log("FiltersParams in JobAboForm:", filtersParams);

  // Use useEffect to update aboFilters when filtersParams changes
  useEffect(() => {
    setAboFilters(filtersParams);
  }, [filtersParams]);


  if (loading) {
    return (
        <div>
          <Skeleton width={"100%"} height={200} variant="wave" />
        </div>
    );
  }

  const handleSubmit = (event, value) => {
    event.preventDefault();
    const theForm = event.target;
    const formContainer = document.getElementsByClassName("formContainer");

    // Bind the FormData object and the form element
    const FD = new FormData(theForm);

    // Log the FormData object to the console (when form is submitted)
    console.log("FormData object contents:", FD);

    function sendData() {
      const XHR = new XMLHttpRequest();

      // Define what happens on successful data submission
      XHR.addEventListener("load", (event) => {
        formContainer.innerHTML = event.target;
        const response = new DOMParser().parseFromString(
            event.target.response,
            "text/html"
        );
        const resultDiv = createSuccessContainer();
        setResponseHtml(resultDiv);

        //console.log(resultDiv);
        console.log("submission successful")
      });

      // Define what happens in case of error
      XHR.addEventListener("error", (event) => {
        alert("Oops! Something went wrong.");
      });

      // Set up our request
      XHR.open(
          "POST",
          Settings.jobAboSubscriptionFormUrl + `?lang=${i18n.lang}`
      );

      // The data sent is what the user provided in the form
      XHR.send(FD);
    }
    sendData();

    //theForm.submit();
  };

  // Function to create the success container
  const createSuccessContainer = () => `
    <div class="successNotification">
      <p>${i18n.translations.jobaboSuccessText1}</p>
      <p style="font-weight:600;">${i18n.translations.jobaboSuccessText2}</p>
    </div>
  `;

  return (
      <div className='jobabo'>
        {responseHtml && (
            <Box autoComplete="off">
              <div
                  className="successContainer"
                  dangerouslySetInnerHTML={{ __html: responseHtml }}
              />
            </Box>
        )}
        {!responseHtml && (
            <Box>
              <form
                  action={Settings.jobAboSubscriptionFormUrl + `?lang=${i18n.lang}`}
                  method="post"
                  autoComplete="off"
                  onSubmit={handleSubmit}
              >
                {props.children}

                {aboFilters["q"] && (
                    <input
                        key="q"
                        type="hidden"
                        name="query"
                        value={aboFilters["q"]}
                    />
                )}
                {aboFilters["workload"] && (
                    <input
                        key="workload"
                        type="hidden"
                        name="workload"
                        value={aboFilters["workload"]}
                    />
                )}

                {aboFilters["f"] &&
                    Object.entries(aboFilters["f"]).map((filter) => (
                        <input
                            type="hidden"
                            key={filter[0]}
                            name={`filter_${filter[0]}`}
                            value={filter[1]}
                        />
                    ))}
              </form>
            </Box>
        )}
      </div>
  );
};

export default JobAboSubscriptionForm;