/**
 * @file index.js
 *
 * This is the entry point for the React app.
 *
 * We'll setup things like the react-query client, and the global context providers here, theming etc.
 * Typically, you won't need to edit this file.
 * For more information on React Context, see https://reactjs.org/docs/context.html
 *
 */

import React, {useState, createContext} from "react";
import {createRoot} from "react-dom/client";

// https://www.npmjs.com/package/react-resizer
// see https://stackoverflow.com/questions/55723467/how-do-i-use-iframe-resizer-with-a-react-js-page-as-content
import "iframe-resizer/js/iframeResizer.contentWindow"; // add this

// React Router, used for routing with the page URL, history etc.
import {
  createBrowserRouter,
  createHashRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";

// Changing CSS injection order to be able to use our stylesheet without !important
import {StyledEngineProvider} from "@mui/material/styles";

// Global Context providers give us access to global state
// See https://tanstack.com/query/v4/docs/react/overview
import {QueryClientProvider, QueryClient} from "@tanstack/react-query";

// App Settings Context, provides global access to settings.js
import {SettingsContextProvider} from "./util/SettingsContext";

// Local Storage Context, provides access to local storage (for Favourites etc.)
import {LocalStorageContextProvider} from "./util/LocalStorageContext";

// Provides project-specific job filters ("attributes")
import {FiltersContextProvider} from "./util/FiltersContext";

// react-query client, this is used for caching and managing API calls
const queryClient = new QueryClient();

import "./index.css";
import ErrorPage from "./ErrorPage";
import JobAboPage from "./JobAboPage";
import PersonalvermittlerPage from "./PersonalvermittlerPage"
import settings from "./settings";
import CareerCenterPage from "./CareerCenterPage";
import IFramePage from "./IFramePage";
import {i18n} from "./util/i18n";
import Header from "./components/Header";
import Footer from "./components/Footer";

// set our language in html dom
document.documentElement.lang = i18n.lang;

const container = document.getElementById("root");
const root = createRoot(container);

/**
 * Wrapper with different React context providers around the app.
 * These mostly provide global data that is used throughout the app, typically
 */
const Wrapper = (props) => (
    <QueryClientProvider client={queryClient}>
      <SettingsContextProvider>
        <LocalStorageContextProvider>
          <FiltersContextProvider>
            <StyledEngineProvider injectFirst>
                <Header />
              {/* <!-- This is where the router will render the page --> */}
              <Outlet/>
                    <Footer />
            </StyledEngineProvider>
          </FiltersContextProvider>
        </LocalStorageContextProvider>
      </SettingsContextProvider>
    </QueryClientProvider>
);

console.log("Creating CC with routerBasename: ", settings.routerBasename);
// create a router object (react router dom v6)
const routerConfig = [
  {
    path: "/",
    element: <Wrapper/>,
    errorElement: <ErrorPage/>,
    //loader: <LoaderPage />,
    children: [
      {index: true, element: <CareerCenterPage/>},
      {path: "jobabo", element: <JobAboPage/>},
      {path: "personalvermittler", element: <PersonalvermittlerPage/>},
    ],
  },
  {
    path: "/iframe",
    element: (
        <SettingsContextProvider>
          <IFramePage/>
        </SettingsContextProvider>
    ),
  },
];

const router = settings.useHashRouter
    ? createHashRouter(routerConfig, {basename: settings.routerBasename})
    : createBrowserRouter(routerConfig, {
      basename: settings.routerBasename,
    });

root.render(
    <React.StrictMode>
      <RouterProvider router={router}></RouterProvider>
    </React.StrictMode>
);