import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#e82025",
        },
        secondary: {
            main: "#0000FF",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 575,
            md: 991,
            lg: 1118,
            xl: 1442,
        },
    },
    typography: {
        fontFamily: [
            '"FrutigerNext"',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiListItemButton: {
            defaultProps: {
                disableTouchRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                disableTouchRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableTouchRipple: true,
                sx: {
                    ":hover": {
                        backgroundColor: "transparent"
                    }
                }
            },
        },
    },
});

import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";


/**
 * Import your components here
 */
import { Button, Typography } from "@mui/material";

/**
 * Main Layout for Personalvermittler
 */
const PersonalvermittlerLayout = ({}) => {
  const { settings, setSettings } = useSettings();


  /**
   * Return Personalvermittler main page
   */
  return (
      <ThemeProvider theme={theme}>
          {/* 2nd Row */}
          <Grid container component="main">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={10} className="pageTitle">
                  <Typography variant="h1">
                      {i18n.translations.personalvermittlerPageTitle}
                  </Typography>
              </Grid>
              <Grid container>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={8} className="introContainer">
                      <Typography className="introduction">
                          {i18n.translations.personalvermittlerPageIntroText}
                      </Typography>
                  </Grid>
              </Grid>

              <Grid container className="redLineContainerTop">
                  <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className="introRedLine"></Grid>
                  <Grid item xs={6} sm={4} md={4} lg={4} xl={4}></Grid>
                  <Grid item xs={0} sm={4} md={4} lg={4} xl={4} sx={{ display: { xs: 'none'} }}></Grid>
              </Grid>

              {/* Content Section */}
              <Grid
                  className="personalvermittlerContentContainer"
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  xs={12} sm={12} md={9} lg={8} xl={8}
              >
                  <Typography>{i18n.translations.personalvermittlerPageContentText1}</Typography>
                  <Typography><br/>{i18n.translations.personalvermittlerPageContentText2}</Typography>
                  <Typography><br/>{i18n.translations.personalvermittlerPageContentText3}</Typography>
                  <Typography><br/>{i18n.translations.personalvermittlerPageContentText4}</Typography>
                  <Typography>{i18n.translations.personalvermittlerPageContentText5}</Typography>
                  <Button target="_blank" href={i18n.translations.personalvermittlerButtonUrl} className="primaryButton">{i18n.translations.personalvermittlerButtonLabel}</Button>
              </Grid>
          </Grid>
      </ThemeProvider>
  );
};

export default PersonalvermittlerLayout;
