import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  IconButton,
  FormControl,
  ListItemIcon,
  Icon,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { i18n } from "../util/i18n";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const dropdownIcon = <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99891 9.03223L0 0.823816L0.76921 0.0344595L7.99891 7.45351L15.2297 0.0322266L16 0.822699L7.99891 9.03223Z" fill="#1A1A1A"/>
    </svg>
;

import {
  getAllOptionsForFilter, // For all options that are available
  filtersParamsToOptions, // For the selected options / current filters
  optionsToFiltersParams, // For converting the selected options back to filtersParams
} from "../util/Conversions";

/**
 * 

 */
const AttributeFilterDropdown = ({
  filtersParams,
  setFiltersParams,
  attributes,
  filterAttributeId,
  onSelectionChanged,
  mode = "single", // or "multiple" or "tree" (later)
  label = "Filter",
  placeholder = i18n.translations.inputSelectPlaceholder,
  ...props
}) => {
  if (!attributes) {
    return <div>Project Attributes are required</div>;
  }
  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }

  // Get all the available options for the filter. This does typically not change over time per filter component.
  // If there are no available options, we don't render anything.
  // Mode "top" means that we only show the top level options, not the children.
  const options = getAllOptionsForFilter(filterAttributeId, attributes, true);

  if (!options) {
    return <></>;
  }

  // The currently selected Options
  const selection = filtersParamsToOptions(filtersParams, attributes)[
    filterAttributeId
  ];
  const [selectedOptions, setSelectedOptions] = useState(selection);

  // Function to determine if there are selected options
  const hasSelectedOptions = selectedOptions && selectedOptions.length > 0;

  // Use selected options as placeholder if available, otherwise use the default placeholder
  const currentPlaceholder = hasSelectedOptions
      ? selectedOptions.map((option) => option.label).join(", ") // Use selected option labels
      : placeholder || (options.length > 0 ? `${options.length} items selected` : '');

  function arrayDiff(a, b) {
    return a.filter((objA) => !b.some((objB) => objA.value === objB.value));
  }

  const count_children_selected = (option, selectedOptions) => {
    console.log("count_children_selected: option", option, selectedOptions);
    console.log("count_children_selected: filterParams", filtersParams);

    if (!option.has_children) {
      return 0;
    }

    return selectedOptions[option.children_filter_id]?.length || 0;
  };

  /**
   *
   * @param {*} event
   * @param {*} value
   */
  const handleChanged = (event, value, reason) => {
    console.log("handleChanged: New value", value);

    console.log("handleChanged: Current selected options", selectedOptions);
    console.log("handleChanged: filterId", filterAttributeId);

    // remove parent/group options
    const newValue = value;
    const diffAdded = arrayDiff(newValue, selectedOptions);
    const diffRemoved = arrayDiff(selectedOptions, newValue);

    if (onSelectionChanged) {
      if (diffAdded.length > 0) {
        // parent added ? only trigger callback with the parent option
        onSelectionChanged(diffAdded[0]);
      }
    }

    /**
     * This was a click on a single option: set value as new filtersParams.
     */
    const selected_options = {
      [filterAttributeId]: value,
    };
    const newParams = optionsToFiltersParams(selected_options, filtersParams);

    setFiltersParams(newParams);
  };

  useEffect(() => {
    // FiltersParams changed, update selection of component
    const selection = filtersParamsToOptions(filtersParams, attributes)[
      filterAttributeId
    ];
    console.log("handleChanged, filtersParams", filtersParams);

    console.log("handleChanged, selection", selection);
    setSelectedOptions(selection || []);
  }, [filtersParams]);


  // see: https://mui.com/material-ui/api/autocomplete/
  return (
    <>
      <Autocomplete
        id={`attribute-filter-group-${filterAttributeId}`}
        options={options}
        value={selectedOptions || []}
        multiple={true}
        onChange={handleChanged}
        fullWidth={true}
        limitTags={3}
        popupIcon={dropdownIcon}
        disableCloseOnSelect={true}
        componentsProps={{}}
        renderTags={(value, getTagProps, ownerState) => {
          // return <Badge sx={{ mx: 0 }} badgeContent={value?.length}></Badge>;
        }}
        isOptionEqualToValue={(option, value) => {
          return option.value == value.value;
        }}
        getOptionLabel={(option) => {
          return option?.label || "";
        }}
        renderOption={(props, option, { selected }) => {
          const isParent = option.has_children ? true : false;

          const numChildrenSelected = count_children_selected(
            option,
            filtersParamsToOptions(filtersParams, attributes)
          );

          return (
            <li {...props} className={isParent ? "parent" : ""}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                value={option.value}
                checked={selected}
              />
              {option.label}{" "}
              <strong>{isParent && (numChildrenSelected > 0 ? `${numChildrenSelected} ausgewählt` : "+")}</strong>
              <ListItemIcon className="dropdownSelectIconSelected">
                {selected ? (
                    <Icon><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.9142 1.92117L6.91421 12.9212C6.52369 13.3117 5.89052 13.3117 5.5 12.9212L0.5 7.92117L1.91421 6.50696L6.20711 10.7999L16.5 0.506958L17.9142 1.92117Z" fill="#1A1A1A"/>
                    </svg>
                    </Icon> // Replace 'check' with the icon name you want to use for selected options
                ) : (
                    <Icon><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.9142 1.92117L6.91421 12.9212C6.52369 13.3117 5.89052 13.3117 5.5 12.9212L0.5 7.92117L1.91421 6.50696L6.20711 10.7999L16.5 0.506958L17.9142 1.92117Z" fill="#FFFFFF"/>
                    </svg>
                    </Icon>
                )}
              </ListItemIcon>
            </li>
          );
        }}
        renderInput={(props) => (
            <FormControl sx={{ width: { xs: '100%'} }}>
              <div>
              <InputLabel variant="standard" className="inputLabel" htmlFor={`attribute-filter-group-${filterAttributeId}`}>
                {label}
              </InputLabel>
              {selectedOptions && selectedOptions.length >= 2 && (
                  <Badge badgeContent={selectedOptions.length} color="primary">
                    {/* This will show the count of selected items */}
                  </Badge>
              )}
              </div>
              <TextField
                  {...props}
                  label={""}
                  placeholder={currentPlaceholder}
                  variant="standard"
                  inputProps={{
                    ...props.inputProps,
                    readOnly: true,
                    style: { cursor: 'pointer' }
                  }}
              />
            </FormControl>
        )}
      ></Autocomplete>
    </>
  );
};

export default AttributeFilterDropdown;
